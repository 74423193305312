import ChatMessageCommand from "@/command/ChatMessageCommand";
import ConditionalCommand from "@/command/ConditionalCommand";
import SpecialIdentifierCommand from "@/command/SpecialIdentifierCommand";
import WebRequestCommand from "@/command/WebRequestCommand";
import WaitCommand from "@/command/WaitCommand";
import CommandRunnerCommand from "@/command/CommandRunnerCommand";
import CounterCommand from "@/command/CounterCommand";
import OverlayCommand from "@/command/OverlayCommand";
import VariableReplacerCommand from "@/command/VariableReplacerCommand";
import CommandCreatorCommand from "@/command/CommandCreatorCommand";
import SpotifyCommand from "@/command/SpotifyCommand";
import ClipCommand from "@/command/ClipCommand";
import TextReplaceCommand from "@/command/TextReplaceCommand";
import ModerationCommand from "@/command/ModerationCommand";
import ShoutoutCommand from "@/command/ShoutoutCommand";

const Commands = {
    ChatMessageCommand,
    ConditionalCommand,
    SpecialIdentifierCommand,
    WebRequestCommand,
    WaitCommand,
    CommandRunnerCommand,
    CounterCommand,
    OverlayCommand,
    VariableReplacerCommand,
    CommandCreatorCommand,
    SpotifyCommand,
    ClipCommand,
    TextReplaceCommand,
    ModerationCommand,
    ShoutoutCommand
}

export default Commands;

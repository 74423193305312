import CommandBase from "@/command/CommandBase";

class ShoutoutCommand extends CommandBase{

    name = 'Shoutout';
    type = 'Shoutout';
    hasNext = false;

    configuration = {}
}

export default ShoutoutCommand;
